import { css, keyframes } from '@emotion/core'
import styled from '@emotion/styled'
import Image from 'gatsby-image'

/*
 * NOTE: use a six-character hex code for all colors to allow alpha channel
 * adjustment without adding extra vars and/or a color manipulation lib.
 *
 * Example:
 *    // use the brand color at 25% opacity
 *    border-color: ${colors.brand}40;
 */
export const colors = {
  brandDarker: '#055701',
  brandDark: '#444',
  brand: '#15ae74',
  theme: '#72C89F',
  gray100: '#f8f9fa',
  gray300: '#dee2e6',
  gray400: '#ced4da',
  gray500: '#adb5bd',
  gray600: '#868e96',
  gray700: '#495057',
  gray900: '#212529',
  themeAccent : '#15ae74',
  brandBright: '#ececec',
  brandLight: '#f8f8f8',
  brandLighter: '#fbfafc',
  lightest: '#ffffff',
  darkest: '#4d4058',
  text: '#333333',
  textMild: '#555555',
  textLight: '#7e718a',
  textLighter: '#aaaaaa',
  lilac: `#222`,
  accent: `#055701`,
  error: `#ec1818`,
  lemon: `#ffdf37`,
  white: '#fff'
}

export const badgeThemes = {
  BUILDWITHGATSBY: {
    level: 1,
    backgroundTheme: colors.brand,
    textTheme: colors.lemon
  },
  HOLYBUCKETS: {
    level: 2,
    backgroundTheme: colors.lemon,
    textTheme: colors.brandDark
  }
}

export const spacing = {
  '3xs': 2,
  '2xs': 4,
  xs: 8,
  sm: 12,
  md: 16,
  lg: 24,
  xl: 32,
  '2xl': 40,
  '3xl': 48
}

export const breakpoints = {
  mobile: 400,
  phablet: 550,
  tablet: 750,
  desktop: 1000,
  hd: 1300
}

export const radius = {
  default: 2,
  large: 4
}

export const defaultFontStack = [
  '-apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Roboto',
  'Oxygen',
  'Ubuntu',
  'Cantarell',
  'Open Sans',
  'Helvetica Neue',
  'sans-serif'
].join()

const monospaceFontStack = [
  `Space Mono`,
  `SFMono-Regular`,
  `Menlo`,
  `Monaco`,
  `Consolas`,
  `Liberation Mono`,
  `Courier New`,
  `monospace`
].join()

export const fonts = {
  body: defaultFontStack,
  heading: `Futura PT, ${defaultFontStack}`,
  monospace: monospaceFontStack
}

export const dimensions = {
  headerHeight: '50px',
  cartWidthDesktop: '600px',
  contributorAreaWidth: {
    closedDesktop: '60px',
    openDesktop: '340px',
    openHd: '420px'
  },
  contributorAreaBarHeight: '50px',
  pictureBrowserAction: {
    widthDesktop: '200px',
    heightMobile: '80px'
  }
}

export const button = {
  default: css`
    background-color: ${colors.lightest};
    border: 1px solid ${colors.brandBright};
    border-radius: ${radius.large}px;
    box-sizing: border-box;
    color: ${colors.brand};
    cursor: pointer;
    display: block;
    font-family: ${fonts.body};
    font-weight: 700;
    margin: 0;
    min-height: 2.25rem;
    text-align: center;
    text-decoration: none;
    transition: 200ms background linear, 100ms border-color linear;
    :focus,
    :hover {
      background: ${colors.brand}0c;
    }
    &[disabled] {
      background-color: ${colors.lightest};
      cursor: not-allowed;
    }
  `,
  small: css`
    font-family: ${fonts.heading};
    padding: 5px 15px;
    font-size: 14px;
  `,
  big: css`
    font-family: ${fonts.heading};
    font-size: 15px;
    padding: 12px 25px;
  `,
  purple: css`
    background: radial-gradient(
      at left top,
      rgb(119, 88, 186) 0%,
      rgb(86, 87, 141) 100%
    );
    border-radius: 50px;
    border-color: ${colors.lilac};
    color: ${colors.lightest} !important;
    cursor: pointer;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    :focus,
    :hover {
      background-color: ${colors.brandDark};
    }
    &[disabled] {
      opacity: 0.6;
    }
  `,
  ghost: css`
    border: 1px solid transparent;
    :focus,
    :hover {
      background: ${colors.lightest};
      border-color: ${colors.brandBright};
    }
  `,
  link: css`
    border: 0;
    background: transparent;
    cursor: pointer;
    padding: 0;
    font-size: 100%;
  `,
}

export const Img = styled(Image)`
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
`

export const scrollbarStyles = {
  WebkitOverflowScrolling: `touch`,
  '&::-webkit-scrollbar': { width: `6px`, height: `6px` },
  '&::-webkit-scrollbar-thumb': { background: colors.brandBright },
  '&::-webkit-scrollbar-thumb:hover': { background: colors.lilac },
  '&::-webkit-scrollbar-track': { background: colors.brandLight }
}

const simpleEntry = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
`

const deadSimpleEntry = keyframes`
  from {
    opacity: .25;
  }
`

export const animations = {
  simpleEntry: `${simpleEntry} .75s ease forwards`,
  deadSimpleEntry: `${deadSimpleEntry} .5s ease forwards`
}
